<template>
  <div>
    <b-card-code title="Scan Group" class="p-0">
      <b-table
        responsive
        id="confTable"
        :fields="conf_fields"
        :items="conf_items"
        :per-page="conf_perPage"
        :current-page="conf_currentPage"
        hover
      >
        <template #cell(agent_type_nicename)="data">
          <span v-b-tooltip.hover.v-dark :title="data.item.description">
            {{ data.item.agent_type_nicename }}
          </span>
        </template>

        <template #cell(actions)="data">
          <div style="white-space: nowrap; overflow: hidden">
            <span
              v-if="data.item.is_active == false"
              v-b-tooltip.hover.v-warning
              title="Upgrade your Plan"
            >
              <b-button
                size="sm"
                variant="primary"
                @click="
                  openNormalScanModal(
                    data.item.agent_type_id,
                    data.item.agent_type_code
                  )
                "
                type="button"
                class="btn btn-primary mr-1"
                style="position: relative"
                :disabled="data.item.is_active == false"
              >
                <span
                  v-if="isloading && data.item.agent_type_id == agent_type_id"
                  ><b-spinner type="border" small></b-spinner> Please wait</span
                >
                <span v-else>Scan Now</span>
              </b-button>
            </span>
            <span v-else>
              <b-button
                size="sm"
                variant="primary"
                @click="
                  openNormalScanModal(
                    data.item.agent_type_id,
                    data.item.agent_type_code
                  )
                "
                type="button"
                class="btn btn-primary mr-1"
                style="position: relative"
              >
                <span
                  v-if="isloading && data.item.agent_type_id == agent_type_id"
                  ><b-spinner type="border" small></b-spinner> Please wait</span
                >
                <span v-else>Scan Now</span>
              </b-button>
            </span>
            <!-- Schedule button -->
            <span
              v-if="data.item.is_active == false"
              v-b-tooltip.hover.v-warning
              title="Upgrade your Plan"
            >
              <b-button
                :disabled="data.item.is_active == false"
                size="sm"
                variant="info"
                @click="
                  openScheduleModal(
                    data.item.agent_type_id,
                    data.item.agent_type_code
                  )
                "
                type="button"
                class="btn btn-primary mr-1"
                style="position: relative"
              >
                Schedule Scan
              </b-button>
            </span>
            <span v-else>
              <b-button
                size="sm"
                variant="info"
                @click="
                  openScheduleModal(
                    data.item.agent_type_id,
                    data.item.agent_type_code
                  )
                "
                type="button"
                class="btn btn-primary mr-1"
                style="position: relative"
              >
                Schedule Scan
              </b-button>
            </span>
          </div>
        </template>
      </b-table>
      <b-pagination
        v-model="conf_currentPage"
        :total-rows="conf_rows"
        :per-page="conf_perPage"
        :align="pagination_pos"
        aria-controls="confTable"
      />
    </b-card-code>

    <b-modal
      ref="modal-customize-meta-burp"
      title="Customise Scan"
      hide-footer
      no-close-on-backdrop
      size="lg"
    >
      <p class="my-1">Customize your scan here.</p>
      <BurpInstantScheduler
        :agent_type_id="agent_type_id"
        :asset_group_id="asset_group_id"
        :asset_id="asset_id"
        :scan_input_meta="scan_input_meta"
        :callBack="afterScan"
        :is_group="true"
      />
    </b-modal>

    <b-modal
      ref="modal-normal-scan"
      title="Scan Options"
      hide-footer
      no-close-on-backdrop
      size="lg"
    >
      <MassScanInstantScheduler
        :agent_type_id="agent_type_id"
        :asset_group_id="asset_group_id"
        :asset_id="asset_id"
        :callBack="afterScan"
        :agent_type="agent_type"
        :is_group="true"
      />
    </b-modal>
    <b-modal
      ref="modal-nessus"
      title="Customise Scan"
      hide-footer
      no-close-on-backdrop
      size="lg"
    >
      <validation-observer ref="ScanNessusForm">
        <b-form
          class="auth-login-form mt-2"
          @submit.prevent="instantScanNessus"
        >
          <b-form-group label="Policy Scope" label-for="Policy Scope">
            <validation-provider
              #default="{ errors }"
              name="Policy Scope"
              rules="required"
            >
              <b-form-select
                v-model="policyScope"
                :options="policyScopeOptions"
                @change="loadPolicyList()"
              ></b-form-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- Scanner-->
          <b-form-group label="Select Policy" label-for="policy">
            <validation-provider
              #default="{ errors }"
              name="Policy"
              rules="required"
            >
              <v-select
                v-model="nessusPolicy"
                label="text"
                placeholder="--Select Policy--"
                :options="policy_filter_options"
                autocomplete
                style="width: 100%"
                :reduce="(nessusPolicy) => nessusPolicy.value"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-button
            type="submit"
            variant="primary"
            :disabled="isBtnDisable"
            size="sm"
          >
            <span v-if="isloading"
              ><b-spinner type="border" small></b-spinner> Please wait</span
            >
            <span v-else>Scan</span>
          </b-button>
        </b-form>
      </validation-observer>
      <!-- <NessusScan
        :agent_type_id="agent_type_id"
        :asset_group_id="asset_group_id"
        :asset_id="asset_id"
        :callBack="afterScan"
        :agent_type="agent_type"
        :is_group="true"
      /> -->
    </b-modal>

    <b-modal
      ref="modal-schedule-scan"
      title="Schedule Scan"
      hide-footer
      no-close-on-backdrop
      size="lg"
    >
      <MassScanScheduler
        :agent_type_id="agent_type_id"
        :asset_group_id="asset_group_id"
        :asset_id="asset_id"
        :callBack="afterScan"
        :agent_type="agent_type"
        :is_group="true"
      />
    </b-modal>

    <b-modal
      ref="modal-schedule-scan-burp"
      title="Schedule Scan"
      hide-footer
      no-close-on-backdrop
      size="lg"
    >
      <BurpScheduler
        :agent_type_id="agent_type_id"
        :asset_group_id="asset_group_id"
        :asset_id="asset_id"
        :callBack="afterScan"
        :agent_type="agent_type"
        :is_group="true"
      />
    </b-modal>

    <!-- Nessus shedule modal -->
    <b-modal
      ref="modal-schedule-scan-nessus"
      title="Schedule Scan"
      hide-footer
      no-close-on-backdrop
      size="lg"
    >
      <NessusScheduler
        :agent_type_id="agent_type_id"
        :asset_group_id="asset_group_id"
        :asset_id="asset_id"
        :callBack="afterScan"
        :agent_type="agent_type"
        :is_group="true"
      />
    </b-modal>
    <!-- Wpscan shedule modal -->
    <b-modal
      ref="modal-schedule-wpscan"
      title="Schedule Scan"
      hide-footer
      no-close-on-backdrop
      size="lg"
    >
      <WpscanScheduler
        :agent_type_id="agent_type_id"
        :asset_group_id="asset_group_id"
        :asset_id="asset_id"
        :callBack="afterScan"
        :agent_type="agent_type"
        :is_group="true"
      />
    </b-modal>
    <!-- Email Security modal -->
    <b-modal
      ref="modal-schedule-scan-dsscan"
      title="Schedule Scan"
      hide-footer
      no-close-on-backdrop
      size="lg"
    >
      <EmailSecurity
        :agent_type_id="agent_type_id"
        :asset_group_id="asset_group_id"
        :asset_id="asset_id"
        :callBack="afterScan"
        :agent_type="agent_type"
        :is_group="true"
      />
    </b-modal>
    <!-- Email IPReputation modal -->
    <b-modal
      ref="modal-schedule-scan-IPReputation"
      title="Schedule Scan"
      hide-footer
      no-close-on-backdrop
      size="lg"
    >
      <IPReputation
        :agent_type_id="agent_type_id"
        :asset_group_id="asset_group_id"
        :asset_id="asset_id"
        :callBack="afterScan"
        :agent_type="agent_type"
        :is_group="true"
      />
    </b-modal>

    <!-- SSL Labs Modal -->
    <b-modal
      ref="modal-schedule-scan-SslLabs"
      title="Schedule Scan"
      hide-footer
      no-close-on-backdrop
      size="lg"
    >
      <!-- :asset_id="asset_id" -->
      <SslLabsScan
        :agent_type_id="agent_type_id"
        :asset_group_id="asset_group_id"
        :callBack="afterScan"
        :agent_type="agent_type"
        :is_group="true"
      />
    </b-modal>
    <b-modal
      ref="modal-schedule-zap-scan"
      title="Schedule Scan"
      hide-footer
      no-close-on-backdrop
      size="lg"
    >
      <ZapScheduler
        :agent_type_id="agent_type_id"
        :asset_group_id="asset_group_id"
        :asset_id="asset_id"
        :callBack="afterScan"
        :agent_type="agent_type"
        :is_group="true"
      />
    </b-modal>
  </div>
</template>



<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";
import {
  BModal,
  BPagination,
  BPaginationNav,
  BButton,
  BRow,
  BCol,
  BBadge,
  BCard,
  BCardHeader,
  BCardBody,
  BFormGroup,
  BFormInput,
  BForm,
  BFormTextarea,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BSidebar,
  VBToggle,
  BSpinner,
  BFormSelect,
  VBTooltip,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import moment from "moment";
import vSelect from "vue-select";
// Instant scans
import MassScanInstantScheduler from "../../Asset/components/AddNormalScan.vue";
import BurpInstantScheduler from "../../Asset/components/AddBurpScan.vue";
import NessusScan from "../../Asset/components/NessusScanner.vue";

//Scheduled scans
import MassScanScheduler from "../../Asset/components/attach_scheduler.vue";
import BurpScheduler from "../../Asset/components/BurpScheduler.vue";
import NessusScheduler from "../../Asset/components/NessusScheduler.vue";
import EmailSecurity from "../../Asset/components/EmailSecurity.vue";
import IPReputation from "../../Asset/components/IpReputation.vue";
import SslLabsScan from "../../Asset/components/SslLabsScan.vue";
import WpscanScheduler from "../../Asset/components/WpscanScheduler.vue";
import ZapScheduler from "../../Asset/components/ZapSchedular.vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BButton,
    BRow,
    BCol,
    BBadge,
    BCard,
    BCardHeader,
    BCardBody,
    BModal,
    BForm,
    BSpinner,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    ValidationProvider,
    ValidationObserver,
    MassScanScheduler,
    BSidebar,
    BurpScheduler,
    NessusScheduler,
    WpscanScheduler,
    ZapScheduler,
    EmailSecurity,
    IPReputation,
    SslLabsScan,
    MassScanInstantScheduler,
    BurpInstantScheduler,
    NessusScan,
    vSelect,
    BFormSelect,
  },
  directives: {
    "b-toggle": VBToggle,
    "b-tooltip": VBTooltip,
    Ripple,
  },
  props: {
    asset_group_id: {
      type: Number,
      required: true,
    },
    callingback: {
      type: Function,
      required: true,
    },
    closeScanSidebar: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      userId: localStorage.getItem("userid"),
      is_staff_login: this.$store.state.app.user.is_staff,

      pagination_pos: "center",
      agent_type: "",
      // Scanner attachment modal
      agent_type_id: "",
      scan_input_meta: "",
      task_title: "",
      asset_id: null,

      // Call orgaization API and return in this format
      conf_items: [],
      conf_currentPage: 1,
      conf_perPage: 10,
      conf_rows: 0,
      conf_fields: [
        { key: "agent_type_nicename", label: "Name" },
        { key: "actions", label: "Actions" },
      ],
      ConfItems: [],
      scan_items: [],
      scan_currentPage: 1,
      scan_perPage: 6,
      scan_rows: 0,
      scan_fields: [
        { key: "scan_id", label: "Scan Id" },
        { key: "agent_type.agent_type_nicename", label: "Type" },
        { key: "scan_status", label: "Status" },
        // { key: "is_scan_paused", label: "Scan Status" },
        { key: "schedule", label: "Schedule" },
        { key: "last_run_at", label: "Last Run" },
        { key: "actions", label: "Actions" },
      ],

      schedule_items: [],
      schedule_currentPage: 1,
      schedule_perPage: 6,
      schedule_rows: 0,
      schedule_fields: [
        { key: "schedule_id", label: "Schedule Id" },
        { key: "agent_type.agent_type_nicename", label: "Type" },
        { key: "scan_input_meta", label: "Scan Info" },
        { key: "last_run_at", label: "Last Run At" },
        { key: "is_paused", label: "Scan Status" },
        { key: "scan_schedule_type", label: "Schedule" },
        { key: "actions", label: "Actions" },
      ],

      polling: null,
      isloading: "",

      isBtnDisable: false,
      nessusPolicy: null,
      policy_filter_options: [],
      policyScope: "public",
      policyScopeOptions: [
        { value: "public", text: "Public" },
        { value: "internal", text: "Internal" },
      ],
    };
  },
  filters: {
    formatdate: function (value) {
      if (value) {
        return moment(String(value)).format("DD-MMM-YY hh:mm A");
      }
    },
  },
  created() {
    // this.load();
    this.loadAgentTypes();
  },
  methods: {
    loadAgentTypes: function () {
      const a_options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "agent/type",
      };
      var self = this;
      this.$http(a_options).then((res) => {
        this.ConfItems = res.data;
        // this.conf_items = this.conf_items.filter(
        //   (item) =>
        //     item.agent_type_code !== "amass" &&
        //     item.agent_type_code !== "emailharvester" &&
        //     item.agent_type_code !== "hibp" &&
        //     item.agent_type_code !== "cidr"
        // );
        const filteredItems = this.ConfItems.filter(
          (item) =>
            item.agent_type_code !== "amass" &&
            item.agent_type_code !== "emailharvester" &&
            item.agent_type_code !== "hibp" &&
            item.agent_type_code !== "cidr" &&
            item.agent_type_code !== "dsscan" &&
            item.agent_type_code !== "prowler" &&
            item.agent_type_code !== "attack_surface" &&
            item.agent_type_code != "cloud_discovery"
        );
        this.conf_items = filteredItems;
        this.conf_rows = this.conf_items.length;
      });
    },
    load: function () {
      this.asset_id = parseInt(this.$route.params.id);
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset/org-asset/" +
          this.$route.params.id +
          "/",
      };

      var self = this;
      this.$http(options).then((res) => {
        this.scan_items = res.data.scans;
        this.scan_rows = res.data.scans.length;
        res.data.schedules.map(function (value, key) {
          res.data.schedules[key].scan_input_meta = JSON.parse(
            res.data.schedules[key].scan_input_meta
          );
        });
        this.schedule_items = res.data.schedules;
        this.schedule_rows = res.data.schedules.length;
      });
    },
    loadScans: function () {
      const s_options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "scan/scan?q_asset_id=" +
          this.$route.params.id,
      };
      this.$http(s_options).then((res) => {
        this.scan_items = res.data;
        this.scan_rows = res.data.length;
      });
    },
    loadSchedules: function () {
      const s_options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "scan/schedule?q_asset_id=" +
          this.$route.params.id,
      };
      this.$http(s_options).then((res) => {
        res.data.map(function (value, key) {
          res.data[key].scan_input_meta = JSON.parse(
            res.data[key].scan_input_meta
          );
        });
        this.schedule_items = res.data;
        this.schedule_rows = res.data.length;
      });
    },
    changeSchedule: function (id, value) {
      let data = {
        is_paused: value,
      };
      const s_options = {
        method: "PATCH",
        data: data,
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "scan/schedule/" + id + "/",
      };
      var self = this;
      this.$http(s_options).then((res) => {
        self.load();
      });
    },
    deleteShedule: function (id) {
      if (confirm("Do you really want to delete this schedule?")) {
        const options = {
          method: "DELETE",
          headers: { "content-type": "application/json" },
          url: process.env.VUE_APP_BASEURL + "scan/schedule/" + id + "/",
        };
        var self = this;
        this.$http(options).then((res) => {
          if (res.data == "") {
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "Successfully deleted",
                icon: "TrashIcon",
                variant: "success",
              },
            });
            // this.loadSchedules();
            this.load();
          }
        });
      }
    },

    // end SslLabs
    loadPolicyList: function () {
      this.nessusPolicy = null;
      this.policy_filter_options = [];
      const a_options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "scan/nessus-policy?policy_scope=" +
          this.policyScope,
      };
      var self = this;
      this.$http(a_options).then((res) => {
        res.data.results.map(function (value, key) {
          let a = {
            value: res.data.results[key].id,
            text: res.data.results[key].policy_name,
          };
          self.policy_filter_options.push(a);
        });
      });
    },

    instantScanNessus: function () {
      this.isBtnDisable = true;
      this.isloading = true;
      //   Adding instant scan:
      let data = {
        agent_type: this.agent_type_id,
        asset_group: this.asset_group_id,
        scan_created_by: this.userId,
        scan_input_meta: "{}",
        is_scan_scheduled: 1,
        scan_schedule_type: 0,
        nessus_policy: this.nessusPolicy,
        url_id: null,
      };
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "scan/schedule/",
      };
      var self = this;
      this.$http(options)
        .then((res) => {
          if (res.data.errors) {
            // this.load();
            self.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.errors,
                // title: "Successfully added the scan request",
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            // this.load();
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "Successfully added the scan request",
                // title: "Trouble while processing the scan request",
                icon: "EditIcon",
                variant: "success",
              },
            });
            this.$refs["modal-nessus"].hide();
            this.closeScanSidebar();
          }
          this.isBtnDisable = false;
          this.isloading = false;
        })
        .catch((err) => {
          console.log(err);
          this.isloading = false;
          this.isBtnDisable = false;
        });
    },
    instantScanWithoutOptions: function () {
      this.isloading = true;
      //   Adding instant scan:
      let data = {
        agent_type: this.agent_type_id,
        asset_group: this.asset_group_id,
        scan_created_by: this.userId,
        scan_input_meta: "{}",
        is_scan_scheduled: 1,
        scan_schedule_type: 0,
        url_id: null,
      };
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "scan/schedule/",
      };
      var self = this;
      this.$http(options)
        .then((res) => {
          if (res.data.errors) {
            // this.load();
            self.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.errors,
                // title: "Successfully added the scan request",
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            // this.load();
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "Successfully added the scan request",
                // title: "Trouble while processing the scan request",
                icon: "EditIcon",
                variant: "success",
              },
            });
            this.closeScanSidebar();
          }
          this.isloading = false;
        })
        .catch((err) => {
          this.isloading = false;
        });
    },
    openNormalScanModal: function (id, type) {
      this.agent_type = type;
      this.agent_type_id = id;
      // if (type == "masscan" || type == "attack_surface")
      //   this.$refs["modal-normal-scan"].show();
      if (type == "masscan" )
        this.$refs["modal-normal-scan"].show();
      else if (type == "burpsuit")
        this.$refs["modal-customize-meta-burp"].show();
      else if (type == "nessus") {
        this.$refs["modal-nessus"].show();
        this.loadPolicyList();
      } else this.instantScanWithoutOptions();
    },
    openScheduleModal: function (id, type) {
      this.agent_type_id = id;
      this.agent_type = type;
      if (type == "masscan" || type == "attack_surface")
        this.$refs["modal-schedule-scan"].show();
      if (type == "burpsuit") this.$refs["modal-schedule-scan-burp"].show();
      if (type == "nessus") this.$refs["modal-schedule-scan-nessus"].show();
      if (type == "wpscan") this.$refs["modal-schedule-wpscan"].show();
      if (type == "dsscan") this.$refs["modal-schedule-scan-dsscan"].show();
      if (type == "ipreputation")
        this.$refs["modal-schedule-scan-IPReputation"].show();
      if (type == "ssllabs") this.$refs["modal-schedule-scan-SslLabs"].show();
      if (type == "zap") this.$refs["modal-schedule-zap-scan"].show();
    },
    afterScan: function (callBackParams) {
      this.$refs[callBackParams].hide();
      // this.loadSchedules();
      // this.load();
      this.callingback();
      this.closeScanSidebar();
    },
    refreshScan: function (id, showtoast) {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "scan/scan/" + id + "/refresh",
      };
      var self = this;
      this.$http(options).then((res) => {
        if (res.data.scan_id) {
          if (showtoast) {
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "Successfully refreshed",
                icon: "EditIcon",
                variant: "success",
              },
            });
          }
          this.load();
          if (res.data.scan_status == 2) {
            this.load(); // This is to load baseline
          }
        }
      });
    },
    deleteScan: function (id) {
      if (
        confirm(
          "Do you really want to delete this scan? This action is irreversible."
        )
      ) {
        const options = {
          method: "DELETE",
          headers: { "content-type": "application/json" },
          url: process.env.VUE_APP_BASEURL + "scan/scan/" + id + "/",
        };
        var self = this;
        this.$http(options).then((res) => {
          if (res.data == "") {
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "Successfully deleted",
                icon: "TrashIcon",
                variant: "success",
              },
            });
            // this.loadScans();
            this.load();
          }
        });
      }
    },
    gotoAssests: function () {
      this.$router.push({ name: "Assets" });
    },
    refreshAllScans: function () {
      var self = this;
      this.polling = setInterval(() => {
        if (self.scan_items.length > 0) {
          self.scan_items.map(function (value, key) {
            self.refreshScan(value.scan_id, false);
            console.log("refreshed", value);
          });
        }
      }, 120000);
    },
  },
  // reference: https://renatello.com/vue-js-polling-using-setinterval/

  beforeDestroy() {
    // clearInterval(this.polling);
  },
};
</script>

<style lang="scss" scoped>
</style>
