<template>
  <b-card-code>
    <!-- <p>{{organization}}organisation</p> -->
    <b-button
      @click="addGroup()"
      type="button"
      variant="primary"
      class="my-1"
      size="sm"
    >
      Add New
    </b-button>
    <!-- <b-button
      @click="showRemoveSidebar()"
      type="button"
      variant="outline-danger"
      class="ml-1"
      size="sm"
    >
      Remove Assigned Group
    </b-button>
    <b-button
      @click="showSidebar()"
      type="button"
      variant="outline-primary"
      class="ml-1"
      size="sm"
    >
      Assign Groups
    </b-button> -->
    <b-card-code class="bg-light-secondary mb-1" title="Search">
      <b-form inline style="display: inline-block; width: 100%">
        <div class="row" style="width: 100%; padding: 5px">
          <!-- <b-form-select
            class="ml-1"
            v-if="is_staff"
            @change="searchFn"
            v-model="organization"
            :options="organizations"
          >
          </b-form-select> -->
          <!-- <v-select
            v-if="is_staff"
            v-model="organization"
            label="text"
            placeholder="--Select Organization--"
            :options="organizations"
            @change="searchFn"
            autocomplete
            style="width: 20%"
            :reduce="organization => organization.value"
          /> -->
          <!-- {{organization}} -->

          <b-form-input
            class="ml-1"
            id="name"
            v-model="name_filter"
            style="width: 70%"
            name="name"
            placeholder="Search with name"
          />
          <b-button
            style="width: 15%"
            class="ml-1"
            variant="primary"
            @click="searchFn"
          >
            <span v-if="isloading"
              ><b-spinner type="border" small></b-spinner> Please wait</span
            >
            <span v-else
              ><feather-icon icon="SearchIcon" class="mr-50" />Search</span
            >
          </b-button>
        </div>
      </b-form>
    </b-card-code>
    <div class="d-flex justify-content-center mb-1" v-if="loading">
      <b-spinner class="float-right" label="Floated Right" />
    </div>
    <div v-if="!loading">
      <b-table
        responsive
        id="atTable"
        :fields="fields"
        :items="items"
        :per-page="perPage"
        v-if="items.length"
        hover
        style="min-height: 200px"
      >
        <template #cell(index)="data">
          {{ data.index + 1 + perPage * (currentPage - 1) }}
        </template>
        <template #cell(group_name)="data">
          <div class="d-flex align-items-center">
            <router-link
              :to="'/assets/group/' + data.item.group_id + '/assets'"
            >
              <b-avatar
                variant="primary"
                size="sm"
                :text="data.item.group_name.charAt(0)"
              />
              <span style="margin-left: 5px">{{ data.item.group_name }}</span>
            </router-link>
          </div>
        </template>
        <template #cell(asset_count)="data">
          <div class="d-flex justify-content-center">
            <b-badge class="" variant="info">{{
              data.item.asset_count
            }}</b-badge>
          </div>
        </template>
        <template #cell(full_name)="data">
          <div class="d-flex">
            <span class="text-capitalize"
              >{{ data.item.groupOwner.full_name }}
              <!-- {{ data.item.groupOwner.first_name }} -->
              <!-- {{ data.item.groupOwner.last_name }} -->
            </span>
          </div>
        </template>
        <template #cell(actions)="data">
          <!-- <button @click="editAssetType(data.item.asset_type_id)" type="button" class="btn btn-primary btn-sm mr-1" style="position: relative;"> Edit</button>
        <button @click="deleteAssetType(data.item.asset_type_id)" type="button" class="btn btn-danger btn-sm mr-1" style="position: relative;"> Delete </button>
         -->
          <b-dropdown
            size="sm"
            class="ml-1 my-dropdown"
            variant="outline-primary"
            text="Actions"
            right
          >
            <b-dropdown-item @click="manageGroup(data.item.group_id)"
              >Manage Assets</b-dropdown-item
            >
            <b-dropdown-item
              @click="
                generateReport(
                  data.item.group_id,
                  data.item.group_name,
                  data.item.groupOwner.profile.organization
                )
              "
              >Generate Report</b-dropdown-item
            >
            <b-dropdown-item @click="editGroup(data.item.group_id)"
              >Edit</b-dropdown-item
            >
            <b-dropdown-item @click="scanNow(data.item.group_id)"
              >Scan Now</b-dropdown-item
            >
            <b-dropdown-divider />
            <b-dropdown-item
              @click="gotoDelete(data.item.group_id, data.item.group_name)"
              >Delete</b-dropdown-item
            >
            <!-- @click="deleteGroup(data.item.group_id,data.item.group_name)" -->
          </b-dropdown>
        </template>
      </b-table>
      <div v-else>
        <div
          class="w-100 d-flex flex-column align-items-center justify-content-center py-4"
        >
          <feather-icon icon="DatabaseIcon" size="34" class="mb-50" />
          <h3 class="font-weight-bolder">No Data Found</h3>
          <p>
            Navigate to add new Group ,
            <span
              class="font-weight-bold text-success cursor-pointer"
              @click="
                $router.push({
                  path: '/assets/group/add',
                })
              "
              >Click here</span
            >
          </p>
        </div>
      </div>
    </div>

    <b-pagination-nav
      v-model="currentPage"
      :number-of-pages="total"
      :link-gen="linkGen"
      :total-rows="rows"
      :per-page="perPage"
      :align="pagination_pos"
      aria-controls="atTable"
      use-router
    />
    <!-- <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      :align="pagination_pos"
      aria-controls="atTable"
    /> -->
    <b-modal
      ref="report_generator"
      title="Generate Report"
      hide-footer
      size="lg"
      no-close-on-backdrop
    >
      <ReportGenerator
        :org_id="organization"
        :report_type="parseInt(2)"
        :group_id="selected_group"
        :parentCallBack="closeModal"
      />
    </b-modal>
    <b-modal
      id="modal-danger"
      hide-footer
      modal-class="modal-danger"
      centered
      title="Delete.. !"
      v-model="openDeleteModal"
      size="lg"
    >
      <b-card-text>
        Do you really want to delete
        <span class="text-primary">{{ group_name }}</span> ?
      </b-card-text>
      <b-form-checkbox
        class="mt-1"
        name="selected-items"
        v-model="isCheck"
        label="Select All"
      >
        Delete All Assets in this group
      </b-form-checkbox>
      <div v-if="isCheck" style="margin-top: 10px">
        <span class="font-italic"
          >This action will remove the following associated records:
        </span>
        <ul class="font-italic">
          <li>Vulnerabilities</li>
          <li>Scans & Schedules</li>
          <li>Tasks</li>
        </ul>
      </div>
      <div class="d-flex justify-content-end">
        <b-button @click="deleteGroup()" variant="danger" size="sm">
          Delete
        </b-button>
      </div>
    </b-modal>
    <b-sidebar
      id="sidebar-scan-now"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      width="50%"
    >
      <GroupScan  :asset_group_id="groupId"
        :callingback="load"
        :closeScanSidebar="closeScanSidebar"/>
    </b-sidebar>
  </b-card-code>
</template>

<script>
import vSelect from "vue-select";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";
import {
  BPagination,
  BPaginationNav,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BForm,
  BFormInput,
  BFormSelect,
  BSpinner,
  BButton,
  VBModal,
  BModal,
  BFormCheckbox,
  BAvatar,
  BBadge,
  BSidebar,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ReportGenerator from "./../common/components/ReportGenerator.vue";
import GroupMapping from "./componets/group_mapping.vue";
import GroupRemoveMapping from "./componets/group-remove-mapping.vue";
import GroupScan from "./componets/group_scan_options.vue"
export default {
  components: {
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    ReportGenerator,
    BForm,
    BFormInput,
    BFormSelect,
    BSpinner,
    BButton,
    vSelect,
    BModal,
    BFormCheckbox,
    BAvatar,
    BBadge,
    GroupMapping,
    GroupRemoveMapping,
    BSidebar,
    GroupScan
  },
  directives: {
    "b-modal": VBModal,
  },
  data() {
    return {
      // Call orgaization API and return in this format
      selected_group: 0,
      pagination_pos: "center",
      // Call orgaization API and return in this format
      items: [],
      currentPage: 1,
      perPage: 10,
      rows: 0,
      total: 1,
      fields: [
        // { key: "group_id", label: "#" },
        { key: "index", label: "#" },
        { key: "group_name", label: "Name" },
        { key: "full_name", label: "Owner" },
        // {
        //   key: "groupOwner.profile.o_profile[0].org_name",
        //   label: "Organization",
        // },
        { key: "asset_count", label: "Asset Count" },
        { key: "group_description", label: "Description" },
        { key: "actions", label: "Actions" },
      ],
      is_staff: this.$store.state.app.user.is_staff,
      organization: this.$store.state.app.org_id,
      // organization: !this.$store.state.app.user.is_staff
      //   ? this.$store.state.app.user.profile.organization
      //   : this.$store.state.app.org_id,
      organizations: [],
      name_filter: "",
      loading: false,
      group_name: "",
      group_id: "",
      isCheck: false,
      openDeleteModal: false,
      isloading: false,
      groupId: null,
    };
  },
  created: function () {
    // this.load();
    // this.loadOrg();
    this.searchFn();
  },
  watch: {
    currentPage: function (newVal, oldVal) {
      this.searchFn(false);
    },
  },
  methods: {
    linkGen: function (pageNum) {
      let current_path = this.$router.currentRoute.path + "?page=" + pageNum;
      return current_path;
    },
    // loadOrg: function () {
    //   if (this.is_staff) {
    //     this.loading = true;

    //     const o_options = {
    //       method: "GET",
    //       headers: { "content-type": "application/json" },
    //       url:
    //         process.env.VUE_APP_BASEURL +
    //         "organization/organization/get-all-org",
    //     };
    //     var self = this;
    //     this.$http(o_options).then((res) => {

    //       res.data.map(function (value, key) {
    //         let org = {
    //           value: res.data[key].org_id,
    //           text: res.data[key].org_name,
    //         };

    //         self.organizations.push(org);
    //       });
    //       this.loading = false;
    //     });
    //   } else {
    //     this.organization = this.$store.state.app.user.profile.organization;
    //     this.searchFn();
    //   }
    // },

    load: function () {
      if (this.$route.query.page) this.currentPage = this.$route.query.page;
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "asset/group",
      };
      this.$http(options).then((res) => {
        console.log(res.data.results);
        // this.toastMessage(res)  // method call to method2
        this.items = res.data.results;
        this.rows = res.data.length;
        this.total = Math.ceil(res.data.count / this.perPage);
      });
    },

    searchFn: function (reset = true) {
      this.isloading = true;
      this.is_search_on = false;
      this.loading = true;
      this.filter = [];
      if (reset) this.currentPage = 1;
      let url =
        process.env.VUE_APP_BASEURL +
        "asset/group?query=true" +
        "&page=" +
        this.currentPage;

      if (this.organization != null) {
        this.filter.push({ organization: this.organization });
        url = url + "&org_id=" + this.organization;
      }
      if (this.name_filter != null && this.name_filter != "") {
        this.filter.push({ name: this.name_filter });
        url = url + "&name=" + this.name_filter;
      }

      console.log(this.filter);
      if (this.filter.length) this.is_search_on = true;
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      this.$http(options).then((res) => {
        this.items = res.data.results;
        this.rows = res.data.length;
        this.total = Math.ceil(res.data.count / this.perPage);
        this.loading = false;
        this.isloading = false;
        //   this.currentPage = 1
        //   this.perPage = 10
        //   this.rows = res.data.count
      });
    },

    addGroup() {
      this.$router.push({ name: "Add Group" });
    },
    editGroup(id, name) {
      this.$router.push({ name: "Add Asset To Group", params: { id: id } });
    },
    manageGroup(id, name) {
      this.$router.push({ name: "Group Assets", params: { id: id } });
    },
    gotoDelete(group_id, group_name) {
      this.openDeleteModal = true;
      this.group_id = group_id;
      this.group_name = group_name;
    },
    deleteGroup() {
      // this.group_name = group_name;
      // if (confirm("Do you really want to delete " + this.group_name + "?")) {
      const options = {
        method: "DELETE",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset/group/" +
          this.group_id +
          "/?is_delete_asset=" +
          this.isCheck,
      };
      this.$http(options).then((res) => {
        console.log(res);
        this.openDeleteModal = false;
        this.searchFn();
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Deleted Successfully",
            icon: "EditIcon",
            variant: "success",
          },
        });
      });
      // }
    },
    generateReport(id, name, organization) {
      this.selected_group = id;
      (this.organization = organization), this.$refs["report_generator"].show();
    },
    closeModal() {
      this.$refs["report_generator"].hide();
    },
    scanNow(group_id) {
      this.groupId = group_id;
      this.$root.$emit("bv::toggle::collapse", "sidebar-scan-now");
    },
    closeScanSidebar(){
        this.$root.$emit("bv::toggle::collapse", "sidebar-scan-now");
    }
    // showRemoveSidebar: function () {
    //   this.$root.$emit("bv::toggle::collapse", "sidebar-remove-assign-handler");
    // },
    // closeRemoveSidebar: function () {
    //   this.$root.$emit("bv::toggle::collapse", "sidebar-remove-assign-handler");
    // },
    // showSidebar: function () {
    //   this.$root.$emit("bv::toggle::collapse", "sidebar-assign-handler");
    // },
    // closeSidebar: function () {
    //   this.$root.$emit("bv::toggle::collapse", "sidebar-assign-handler");
    // },
  },
};
</script>
<style scoped>
::v-deep .dropdown-menu {
  max-height: 200px;
  overflow-y: auto;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>